<template>
  <div class="trans">
    <div class="mt-sm-7 mt-3 mb-sm-5 mb-4 black--text text-sm-h5 f18 font-weight-bold">{{ $t('portfolio.transactionsTitle') }}</div>
    <v-data-table
      class="d-md-block d-none"
      hide-default-footer
      fixed-header
      hide-default-header
      :headers="headers"
      :items="data.results"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="i in $t('portfolio.transactionsTable')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:no-data v-if="!data.pages">
        <div class="d-flex flex-column align-center text-center my-5">
          <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="black--text" style="height: 82px">
            <td class="text-body-1 text-no-wrap">
              <span v-if="!$vuetify.rtl" class="symbol_card mr-5">{{ item.security.exchange_symbol }}</span>
              <span>{{ item.security.company_name }}</span>
              <span v-if="$vuetify.rtl" class="symbol_card ml-5">{{ item.security.exchange_symbol }}</span>
              <span :class="item.action == 'purchase' ? 'triangle-top' : 'triangle-bottom'" class="ms-3"></span>
            </td>
            <td class="text-body-1 text-capitalize">{{ item.action }}</td>
            <td class="text-body-1">{{ item.unit_price / 100 }}</td>
            <td class="text-body-1">{{ item.count }}</td>
            <td class="text-body-1">{{ item.price / 100 }}</td>
            <td class="text-body-1">
              {{
                new Date(item.created_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <div class="mt-2 pb-2 px-3 d-flex align-center" v-if="data.pages > 1">
          <v-spacer></v-spacer>
          <v-pagination
            color="white"
            prev-icon="mdi-arrow-left-thin"
            next-icon="mdi-arrow-right-thin"
            @input="getData"
            v-model="page"
            :length="data.pages"
            :total-visible="5"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
    <div class="d-md-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.pages">
        <img height="200" src="@/assets/icon/noStock.svg" alt="no data" />
        <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
      </div>
      <div v-else v-for="item in data.results" :key="item.id">
        <div class="gray--text mb-2">
          {{
            new Date(item.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour12: false,
              hour: 'numeric',
              minute: 'numeric',
            })
          }}
        </div>
        <v-card flat class="d-flex align-center pa-3 black--text rounded-lg mb-2" min-height="58">
          <div class="font-weight-bold text-truncate">
            {{ item.security.exchange_symbol }}
            <span :class="item.action == 'purchase' ? 'triangle-top' : 'triangle-bottom'" class="ms-3"></span>
          </div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold ms-3">SAR {{ item.price / 100 }}</div>
        </v-card>
      </div>
      <div class="mt-2 pb-2 mx-auto" v-if="data.pages > 1">
        <v-pagination
          color="white"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getData"
          v-model="page"
          :length="data.pages"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      headers: [1, 1, 1, 1, 1, 1],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.error = [];
      await this.$store.dispatch('getPortfolioSecuritiesTrans', this.page).catch((e) => {
        this.error = e.response.status;
        if (e.response.data.error[0] === 'page__out_of_bounds') {
          this.page = 1;
          this.getData();
        }
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.portfolioSecuritiesTrans;
    },
  },
};
</script>

<style lang="scss">
.trans {
  .symbol_card {
    padding: 12px 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--v-primary-base);
    color: white !important;
  }
}
</style>
