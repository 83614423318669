<template>
  <div class="stoks">
    <div class="mt-sm-10 mt-3 mb-sm-5 mb-4 black--text text-sm-h5 f18 font-weight-bold">{{ $t('portfolio.stockTitle') }}</div>
    <v-data-table
      class="d-sm-block d-none"
      hide-default-footer
      fixed-header
      hide-default-header
      :headers="headers"
      :items="data"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="i in $t('portfolio.stockTable')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:no-data v-if="!data.length">
        <div class="d-flex flex-column align-center text-center my-5">
          <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="black--text" style="height: 82px">
            <td class="text-body-1 text-no-wrap" style="width: 100px">
              <span class="symbol_card">{{ item.security.exchange_symbol }}</span>
            </td>
            <td class="text-body-1 font-weight-bold">{{ item.security.company_name }}</td>
            <td class="text-body-1">{{ item.security.market_sector ? item.security.market_sector.name : '' }}</td>
            <td class="text-body-1">{{ item.count }}</td>
            <td class="text-body-1 font-weight-black">{{ (item.value / 100).toFixed(2) }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="d-sm-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.length">
        <img height="200" src="@/assets/icon/noStock.svg" alt="no data" />
        <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
      </div>
      <v-card
        v-else
        v-for="item in data"
        :key="item.id"
        flat
        class="d-flex align-center pa-3 black--text rounded-lg mb-2"
        min-height="58"
      >
        <div class="font-weight-bold me-3  text-truncate">
          <span class="symbol_card me-3">{{ item.security.exchange_symbol }}</span>{{ item.security.company_name }}
        </div>
        <v-spacer></v-spacer>
        <div>{{ (item.value / 100).toFixed(2) }}</div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [1, 1, 1, 1, 1],
    };
  },
  computed: {
    data() {
      return this.$store.getters.portfolioSecurities;
    },
  },
};
</script>

<style lang="scss">
.stoks {
  .symbol_card {
    padding: 12px 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--v-primary-base);
    color: white !important;
  }
  @media screen and (max-width: 599px) {
    .symbol_card {
      display: inline-block;
      font-size: 14px;
      padding: 6px 8px;
      border-radius: 6px;
    }
  }
}
</style>
