<template>
  <div class="plan">
    <div class="mt-4 mb-sm-5 mb-4 d-flex align-sm-end flex-sm-row flex-column">
      <div class="black--text text-sm-h5 f18 font-weight-bold mb-sm-0 mb-4">{{ $t('portfolio.planTitle') }}</div>
      <v-spacer></v-spacer>
      <v-btn
        @click="modal = true"
        depressed
        class="black rounded"
        dark
        :height="$vuetify.breakpoint.xs ? 56 : 60"
        :width="$vuetify.breakpoint.xs ? '100%' : 198"
      >
        <v-icon color="white" class="me-4">mdi-plus-box</v-icon>{{ $t('btn.stock') }}
      </v-btn>
    </div>
    <v-card
      flat
      class="error lighten-5 black--text f18 d-flex align-center px-sm-4 px-3 mb-5 rounded-lg font-weight-bold"
      min-height="68"
      v-if="data.status == 'validation_error'"
    >
      <img class="d-block px-sm-3 ps-1 pe-3 red-filter" src="@/assets/icon/warning.svg" alt="warning" /> {{ $t('portfolio.errorTitle') }}
    </v-card>
    <v-card
      flat
      class="mb-5 d-flex flex-md-nowrap flex-wrap align-center action-card px-sm-7 px-3 py-5"
      min-height="98"
      v-if="data.status && data.status != 'unmodified'"
    >
      <div class="black--text">
        <div class="f18 font-weight-bold mb-1">{{ $t('portfolio.actionTitle') }}</div>
        <div>{{ $t('portfolio.actionDesc') }}</div>
      </div>
      <v-spacer class="d-md-block d-none"></v-spacer>
      <div class="d-flex ms-md-3 mt-md-0 mt-6 mx-md-0 mx-auto">
        <v-btn @click="discard" :width="$vuetify.breakpoint.xs ? 150 : 198" :height="$vuetify.breakpoint.xs ? 48 : 60" outlined>
          <span class="black--text">{{ $t('btn.discard') }}</span>
        </v-btn>
        <v-btn
          :disabled="data.status == 'validation_error'"
          @click="apply"
          depressed
          :width="$vuetify.breakpoint.xs ? 150 : 198"
          :height="$vuetify.breakpoint.xs ? 48 : 60"
          class="primary ms-sm-4 ms-3"
        >
          {{ $t('btn.apply') }}
        </v-btn>
      </div>
    </v-card>
    <v-data-table
      class="d-sm-block d-none"
      hide-default-footer
      fixed-header
      hide-default-header
      :headers="headers"
      :items="data.positions"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="i in $t('portfolio.planTable')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:no-data v-if="!data.positions.length">
        <div class="d-flex flex-column align-center text-center my-5">
          <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="item.id"
            :class="
              item.status == 'add'
                ? 'success'
                : item.status == 'modified'
                ? 'yellow lighten-5'
                : item.status == 'removed'
                ? 'error lighten-5'
                : ''
            "
            class="black--text"
            style="height: 82px"
          >
            <td class="text-body-1 text-no-wrap"  style="width: 100px">
              <span class="symbol_card" :class="item.status == 'removed' ? 'gray' : ''">{{ item.security.exchange_symbol }}</span>
            </td>
            <td class="text-body-1 font-weight-bold" :class="item.status == 'removed' ? 'error--text' : ''">
              {{ item.security.company_name }}
            </td>
            <td class="text-body-1">{{ item.security.market_sector ? item.security.market_sector.name : '' }}</td>
            <td class="text-body-1">
              <div class="d-flex align-center" v-if="myItem == i + 1">
                <v-text-field
                  :id="`input${i}`"
                  class="field64 percent mt-n1 me-5"
                  v-model="item.percent_updated_new"
                  hide-details
                  dense
                  outlined
                  color="primary"
                  height="52"
                ></v-text-field>
                <v-btn @click="updateItem(item)" small icon><v-icon color="#219653">mdi-check</v-icon></v-btn>
                <v-divider vertical class="mx-5 my-3"></v-divider>
                <v-btn @click="myItem = ''" small icon><v-icon color="error">mdi-close</v-icon></v-btn>
              </div>
              <div v-else class="d-flex" @click="openInput(i)">
                <div class="percent text-center width100 link">{{ myItem == i + 1 ? '' : item.percent_updated / 100 }}</div>
                <div style="width: 250px"></div>
              </div>
            </td>
            <td style="width: 80px">
              <v-btn @click="openRemove(item)" class="filter" icon><img src="@/assets/icon/delete.svg" alt="remove" /></v-btn>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <div class="py-5 px-7 d-flex align-center">
          <img class="d-block" src="@/assets/icon/warning.svg" alt="warning" />
          <div class="ps-3 gray--text">{{ $t('portfolio.planTableFooter') }}</div>
          <v-spacer></v-spacer>
          <div style="width: 35%" :class="data.status == 'validation_error' ? 'error--text' : 'black--text'" class="font-weight-bold">
            {{ data.percent_total / 100 }}%
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="d-sm-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.positions.length">
        <img height="200" src="@/assets/icon/noStock.svg" alt="no data" />
        <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
      </div>
      <div v-else v-for="item in data.positions" :key="item.id">
        <v-card
          flat
          class="d-flex align-center pa-3 black--text rounded-lg mb-2"
          min-height="58"
          @click="openItem(item)"
          :class="
          item.status == 'add'
            ? 'success'
            : item.status == 'modified'
            ? 'yellow lighten-5'
            : item.status == 'removed'
            ? 'error lighten-5'
            : ''
          "
        >
          <div class="font-weight-bold text-truncate">
            <span class="symbol_card me-2" :class="item.status == 'removed' ? 'gray' : ''">{{ item.security.exchange_symbol }}</span>
            {{ item.security.company_name }}
          </div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold ms-3">{{ item.percent_updated / 100 }}%</div>
        </v-card>
      </div>
      <v-card flat class="pa-3 d-flex align-center rounded-lg" min-height="58" v-if="data.positions.length">
        <img class="d-block" src="@/assets/icon/warning.svg" alt="warning" />
        <div class="ps-3 gray--text">{{ $t('portfolio.planTableFooter') }}</div>
        <v-spacer></v-spacer>
        <div :class="data.status == 'validation_error' ? 'error--text' : 'black--text'" class="font-weight-bold ms-3">
          {{ data.percent_total / 100 }}%
        </div>
      </v-card>
    </div>
    <v-dialog v-model="modal" :max-width="$vuetify.breakpoint.xs ? 400 : 600">
      <v-card class="px-sm-10 px-4 py-sm-7 py-6">
        <div class="modal-title mb-sm-0 mb-1">{{ $t('portfolio.addModalTitle') }}</div>
        <div class="gray--text mb-4 text-sm-body-1 text-body-2">{{ $t('portfolio.addModalDesc') }}</div>
        <v-btn class="close-btn" @click="modal = false" icon><v-icon color="black">mdi-close</v-icon></v-btn>
        <v-card flat tile height="392" class="view-without-scroll pr-2" id="list" @scroll="nextPage">
          <div
            v-for="i in array"
            :key="i.id"
            @click="stock = i.id"
            class="px-sm-6 px-5 py-sm-5 py-4 select-stock"
            :class="stock == i.id ? 'white--text primary' : 'black--text'"
            style="min-height: 60px"
          >
            <span class="font-weight-bold pe-5">{{ i.exchange_symbol }}</span> {{ i.company_name }}
          </div>
          <v-card v-if="load" tile flat class="d-flex justify-center align-center py-1">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </v-card>
        </v-card>
        <div class="text-center mt-4">
          <v-btn
            :disabled="!stock"
            @click="addItem"
            color="primary"
            depressed
            :width="$vuetify.breakpoint.xs ? 230 : 384"
            :height="$vuetify.breakpoint.xs ? 48 : 64"
          >
            {{ $t('btn.confirm') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalRemove" :max-width="$vuetify.breakpoint.xs ? 400 : 540">
      <v-card class="pb-sm-11 pt-sm-13 py-6 black--text">
        <div :style="`max-width: ${$vuetify.breakpoint.xs ? 230 : 420}px`" class="text-center mx-auto">
          <div class="font-weight-bold text-sm-h5 f18 mb-5">{{ $t('portfolio.modalRemoveTitle') }}</div>
          <v-card
            flat
            class="d-flex justify-center align-center px-sm-5 px-4 py-3 gray lighten-1 font-weight-bold black--text"
            v-if="modalRemove"
          >
            <div class="primary white--text pa-2 me-2 rounded-lg">{{ modalData.security.exchange_symbol }}</div>
            {{ modalData.security.company_name }}
          </v-card>
          <div class="mt-5 px-sm-1 text-sm-body-1 text-body-2">{{ $t('portfolio.modalRemoveDesc') }}</div>
          <v-btn class="close-btn" @click="modalRemove = false" icon><v-icon color="black">mdi-close</v-icon></v-btn>
          <div style="max-width: 300px" class="text-center mx-auto mt-sm-10 mt-3">
            <v-btn @click="removeItem" color="error" depressed block :height="$vuetify.breakpoint.xs ? 48 : 60">
              {{ $t('btn.delete') }}
            </v-btn>
            <v-btn
              outlined
              @click="modalRemove = false"
              color="gray lighten1"
              class="mt-3"
              depressed
              block
              :height="$vuetify.breakpoint.xs ? 48 : 60"
            >
              <span class="black--text">{{ $t('btn.cancel') }}</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalItem" max-width="400">
      <v-card class="black--text py-6 px-4">
        <div class="text-center mx-auto" style="max-width: 230px">
          <div class="font-weight-bold text-sm-h5 f18 mb-5">{{ $t('portfolio.modalEditTitle') }}</div>
          <v-card
            flat
            class="d-flex justify-center align-center mx-auto px-4 py-3 mb-4 gray lighten-1 font-weight-bold black--text"
            v-if="modalItem"
          >
            <div class="primary white--text pa-2 me-2 rounded-lg">{{ modalData.security.exchange_symbol }}</div>
            {{ modalData.security.company_name }}
          </v-card>
          <div class="gray--text text-body-2">{{ $t('input.percentPla') }}, %</div>
          <v-text-field
            class="field64 percent center mb-6"
            v-model="modalData.percent_updated_new"
            hide-details
            dense
            outlined
            color="primary"
            height="52"
          ></v-text-field>
          <v-btn @click="updateItem(modalData)" color="primary" depressed block height="48">{{ $t('btn.confirm') }}</v-btn>
        </div>
        <v-btn class="close-btn" @click="modalItem = false" icon><v-icon color="black">mdi-close</v-icon></v-btn>
        <div class="d-flex align-center mt-6">
          <v-divider></v-divider>
          <div class="px-3 gray--text">or</div>
          <v-divider></v-divider>
        </div>
        <div style="max-width: 230px" class="text-center mx-auto mt-6">
          <v-btn @click="modalRemove = true" color="error" depressed block height="48">{{ $t('btn.delete') }}</v-btn>
          <div class="mt-3 text-body-2">{{ $t('portfolio.modalRemoveDesc') }}</div>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar :timeout="3000" v-model="snackbar" centered top tile color="error">{{ $t('portfolio.stockAlreadyAdd') }}.</v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      modalRemove: false,
      modalItem: false,
      snackbar: false,
      load: false,
      modalData: {},
      headers: [1, 1, 1, 1, 1],
      array: [],
      page: 1,
      stock: '',
      myItem: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getPlanList');
    this.getStockList();
  },
  methods: {
    openInput(i) {
      this.myItem = i + 1;
      setTimeout(() => {
        document.getElementById(`input${i}`).focus();
      }, 300);
    },
    openRemove(i) {
      this.modalData = i;
      this.modalRemove = true;
    },
    openItem(i) {
      this.modalData = i;
      this.modalItem = true;
    },
    nextPage() {
      const all = document.getElementById('list').scrollHeight;
      const scroll = document.getElementById('list').scrollTop;
      const height = document.getElementById('list').clientHeight;
      if (all == scroll + height) {
        ++this.page;
        this.$nextTick(() => {
          if (this.page <= this.stockList.pages) {
            this.getStockList();
          }
        });
      }
    },
    async getStockList() {
      this.load = true;
      const data = new Object();
      data.page = this.page;
      this.$store.dispatch('getSecuritiesList', data).finally(() => {
        this.load = false;
      });
    },
    async apply() {
      await this.$store.dispatch('applyPlanItem', this.modalData.id);
    },
    async discard() {
      await this.$store.dispatch('discardPlanItem', this.modalData.id);
    },
    async addItem() {
      this.error = [];
      const data = new Object();
      data.percent = 0;
      data.security = this.stock;
      await this.$store
        .dispatch('addPlanItem', data)
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((i) => i == 'security__already_added') ? (this.snackbar = true) : '';
          this.error.find((i) => i == 'questionnaire__not_provided') ? this.$router.push(`/question/start?page=${this.$route.path}`) : '';
        });
    },
    async updateItem(i) {
      this.error = [];
      const data = new Object();
      data.percent = i.percent_updated_new * 100;
      await this.$store
        .dispatch('updatePlanItem', { data: data, id: i.id })
        .then(() => {
          this.myItem = '';
          this.modalItem = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((i) => i == 'questionnaire__not_provided') ? this.$router.push(`/question/start?page=${this.$route.path}`) : '';
        });
    },
    async removeItem() {
      await this.$store.dispatch('removePlanItem', this.modalData.id).then(() => {
        // this.data.positions.splice(this.idx, 1);
        this.$store.dispatch('getPlanList');
        this.modalRemove = false;
        this.modalItem = false;
      });
    },
  },
  watch: {
    modal() {
      this.stock = '';
      this.error = [];
    },
    stockList(val) {
      if (val) {
        for (let i = 0; i < this.stockList.results.length; i++) {
          this.array.push(this.stockList.results[i]);
        }
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.planList;
    },
    stockList() {
      return this.$store.getters.securitiesList;
    },
  },
};
</script>

<style lang="scss">
.select-stock {
  cursor: pointer;
  border-bottom: thin solid var(--v-gray-lighten1);
}
.select-stock:hover {
  background: var(--v-gray-lighten1);
}
.modal-title {
  color: var(--v-black-base);
  font-weight: 700;
  font-size: 30px;
}
@media screen and (max-width: 599px) {
  .modal-title {
    font-size: 18px;
  }
}
.plan {
  .action-card {
    border: 1px solid var(--v-primary-base);
    background: #f9f9f9;
    border-radius: 10px;
  }
  .symbol_card {
    padding: 12px 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--v-primary-base);
    color: white !important;
    white-space: nowrap !important;
  }
  .filter {
    filter: opacity(0.45);
  }
  .red-filter {
    filter: invert(42%) sepia(57%) saturate(756%) hue-rotate(315deg) brightness(100%) contrast(90%);
  }
  .percent {
    max-width: 120px;
    input {
      text-align: center;
    }
  }
  @media screen and (max-width: 599px) {
    .symbol_card {
      display: inline-block;
      font-size: 14px;
      padding: 6px 8px;
      border-radius: 6px;
      height: 27px;
    }
  }
}
</style>
