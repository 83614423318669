<template>
  <div class="evaluation">
    <v-card flat class="mt-sm-5 mt-2 px-sm-7 px-4 py-sm-6 pt-5 pb-3 black--text rounded-lg">
      <div class="font-weight-medium text-sm-body-1 text-caption">{{ $t('portfolio.evaluation') }}:</div>
      <div class="font-weight-bold mt-1">
        <span class="text-h4 font-weight-bold">SAR {{ (summery.value / 100 || 0).toFixed(2) }}</span>
        <!-- <span class="gray--text f18 ps-8"> -->
        <!-- <span :class="true ? 'triangle-top' : 'triangle-bottom'"></span> -->
        <!-- {{ (summery.change / 100 || 0).toFixed(2) }} ({{ (summery.change_percentage / 100 || 0).toFixed(2) }} %) -->
        <!-- </span> -->
      </div>
    </v-card>
    <Performance v-if="load" />
  </div>
</template>

<script>
import Performance from '@/components/home/Performance.vue';

export default {
  components: {
    Performance,
  },
  data() {
    return {
      load: false,
    };
  },
  mounted() {
    this.$store.dispatch('getPortfolioSummary').finally(() => {
      setTimeout(() => {
        this.load = true;
      }, 100);
    });
  },
  computed: {
    summery() {
      return this.$store.getters.portfolioSummary;
    },
  },
};
</script>
