<template>
  <div class="history">
    <div class="mt-sm-7 mt-3 mb-sm-5 mb-4 black--text text-sm-h5 f18 font-weight-bold">{{ $t('portfolio.historyTitle') }}</div>
    <v-data-table
      class="d-md-block d-none"
      hide-default-footer
      fixed-header
      hide-default-header
      :headers="headers"
      :items="data.results"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="i in $t('portfolio.historyTable')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:no-data v-if="!data.pages">
        <div class="d-flex flex-column align-center text-center my-5">
          <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="black--text" style="height: 82px">
            <td class="text-body-1 text-no-wrap">
              <span v-if="!$vuetify.rtl" class="symbol_card mr-5" :class="item.action == 'remove' ? 'gray' : ''">
                {{ item.security.exchange_symbol }}
              </span>
              <span :class="item.action == 'remove' ? 'text-decoration-line-through' : ''">{{ item.security.company_name }}</span>
              <span v-if="$vuetify.rtl" class="symbol_card ml-5" :class="item.action == 'remove' ? 'gray' : ''">
                {{ item.security.exchange_symbol }}
              </span>
            </td>
            <td class="text-body-1 text-capitalize">{{ item.action }}</td>
            <td class="text-body-1">
              <span v-if="item.action == 'update' || item.action == 'remove'">{{ item.percent_before / 100 }}%</span>
              <img v-if="item.action == 'update'" class="px-2" src="@/assets/icon/arrow-right.svg" alt="arrow" />
              <span v-if="item.action != 'remove'">{{ item.percent_after / 100 }}%</span>
              <span
                v-if="item.action == 'update'"
                :class="item.percent_before < item.percent_after ? 'triangle-top' : 'triangle-bottom'"
                class="ms-3"
              ></span>
            </td>
            <td class="text-body-1">
              {{
                new Date(item.created_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <div class="mt-2 pb-2 px-3 d-flex align-center" v-if="data.pages > 1">
          <v-spacer></v-spacer>
          <v-pagination
            color="white"
            prev-icon="mdi-arrow-left-thin"
            next-icon="mdi-arrow-right-thin"
            @input="getData"
            v-model="page"
            :length="data.pages"
            :total-visible="5"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
    <div class="d-md-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.pages">
        <img height="200" src="@/assets/icon/noStock.svg" alt="no data" />
        <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
      </div>
      <div v-else v-for="item in data.results" :key="item.id">
        <div class="gray--text mb-2">
          {{
            new Date(item.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour12: false,
              hour: 'numeric',
              minute: 'numeric',
            })
          }}
        </div>
        <v-card flat class="mob-row pa-3 black--text rounded-lg mb-2" min-height="58">
          <div class="font-weight-bold text-truncate" :class="item.action == 'remove' ? 'text-decoration-line-through' : ''">
            {{ item.security.company_name }}
          </div>
          <div class="text-capitalize gray--text px-2">{{ item.action ? item.action.substr(0, 3) : '' }}.</div>
          <div class="text-end">
            <span v-if="item.action == 'update' || item.action == 'remove'">{{ item.percent_before / 100 }}%</span>
            <img v-if="item.action == 'update'" class="px-2" src="@/assets/icon/arrow-right.svg" alt="arrow" />
            <span v-if="item.action != 'remove'">{{ item.percent_after / 100 }}%</span>
            <span
              v-if="item.action == 'update'"
              :class="item.percent_before < item.percent_after ? 'triangle-top' : 'triangle-bottom'"
              class="ms-3"
            ></span>
          </div>
        </v-card>
      </div>
      <div class="mt-2 pb-2 mx-auto" v-if="data.pages > 1">
        <v-pagination
          color="white"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getData"
          v-model="page"
          :length="data.pages"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      headers: [1, 1, 1, 1],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.error = [];
      await this.$store.dispatch('getPlanListHistory', this.page).catch((e) => {
        this.error = e.response.status;
        if (e.response.data.error[0] === 'page__out_of_bounds') {
          this.page = 1;
          this.getData();
        }
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.planListHistory;
    },
  },
};
</script>

<style lang="scss">
.history {
  .symbol_card {
    padding: 12px 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--v-primary-base);
    color: white !important;
  }
  .mob-row {
    display: grid;
    grid-template-columns: 54% auto 152px;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    .mob-row {
      grid-template-columns: auto 52px 143px;
      .text-capitalize,
      .text-end {
        font-size: 14px;
      }
    }
  }
}
</style>
