<template>
  <div class="portfolio">
    <div class="text-h4 font-weight-bold mb-ms-6 mb-3">{{ $t('portfolio.title') }}</div>
    <v-tabs class="d-sm-block d-none" background-color="transparent" v-model="link">
      <v-tab class="px-5 text-body-1" :to="`/portfolio/${tabs[i]}`" v-for="(item, i) in $t('portfolio.tabs')" :key="item">{{ item }}</v-tab>
    </v-tabs>
    <div class="d-sm-none d-flex pb-3 mob-tab">
      <v-btn
        depressed
        :color="$route.params.tab == tabs[i] ? 'primary' : 'accent'"
        :to="`/portfolio/${tabs[i]}`"
        v-for="(item, i) in $t('portfolio.tabs')"
        :key="item"

      >
        <span :class="$route.params.tab == tabs[i] ? '' : 'gray--text'">{{ item }}</span>
      </v-btn>
    </div>
    <v-divider class="d-sm-block d-none"></v-divider>
    <v-tabs-items v-model="link">
      <v-tab-item class="accent" v-if="link == '/portfolio/overview'" value="/portfolio/overview"><Overview /></v-tab-item>
      <v-tab-item class="accent" v-if="link == '/portfolio/stoks'" value="/portfolio/stoks"><Stoks /></v-tab-item>
      <v-tab-item class="accent" v-if="link == '/portfolio/plan'" value="/portfolio/plan"><Plan /></v-tab-item>
      <v-tab-item class="accent" v-if="link == '/portfolio/history'" value="/portfolio/history"><History /></v-tab-item>
      <v-tab-item class="accent" v-if="link == '/portfolio/transactions'" value="/portfolio/transactions"><Transactions /></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Overview from '../components/portfolio/Overview.vue';
import Stoks from '../components/portfolio/Stoks.vue';
import Plan from '../components/portfolio/Plan.vue';
import History from '../components/portfolio/History.vue';
import Transactions from '../components/portfolio/Transactions.vue';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Overview,
    Stoks,
    Plan,
    History,
    Transactions,
  },
  data() {
    return {
      link: this.$route.path,
      tabs: ['overview', 'stoks', 'plan', 'history', 'transactions'],
    };
  },
  mounted() {
    this.$route.params.tab != 'overview' ? this.$store.dispatch('getPortfolioSecurities') : '';
  },
  destroyed() {
    this.$store.dispatch('setPortfolio');
    this.$store.dispatch('setPlanListHistory');
  },
};
</script>

<style lang="scss">
#app .portfolio {
  .v-divider {
    margin-top: -2px;
    height: 2px;
    border-width: 1px;
    border-color: #ebeff4;
  }
  .mob-tab {
    overflow-x: auto;
    scrollbar-width: none;
  }
  .mob-tab::-webkit-scrollbar {
    display: none;
  }
}
</style>
